
import { Component, Mixins } from 'vue-property-decorator';
import Page from '@/views/Page.vue';
import OrgCard from '@/components/orgAdmin/OrgCard.vue';
import { VuetifyMixin, StringsMixin, BAIconsMixin, OrgResolverMixin, AthleteReportsMixin, OrgAdminRoutingMixin } from '@/mixins';
import { OrganizationModel } from '@/models/organization/OrganizationModel';
import { orgAdminStore, sportStore } from '@/store';
import { AthleteReportCategoryHeading } from '@/models';
import { cloneDeep } from 'lodash';

@Component({
	components: {
		Page,
		OrgCard,
	}
})
export default class OrgAdminManageTeams extends Mixins(VuetifyMixin, StringsMixin, BAIconsMixin, OrgResolverMixin, AthleteReportsMixin, OrgAdminRoutingMixin) {
	// organizationId is inherited from OrgResolverMixin
	// @Prop({ required: true }) organizationId: string;

	async created() {
		await this.loadCurrentOrganization();
	}

	async OrgSportAthleteReportHeadings(): Promise<Array<AthleteReportCategoryHeading>> {
		const sport = await sportStore.findSportByName(this.CurrentOrganization.sport);
		if( this.IsEmpty(sport) ) return [];
		return sport.athleteReportHeadings
	}
	editOrganization: OrganizationModel;
	editAthleteReportsReady: boolean = false;
	async organizationLoadComplete() {
		if( this.IsEmpty(this.CurrentOrganization.athleteReportHeadings) ) {
			if( this.IsNotEmpty(this.CurrentOrganization.sport) ) {
				const headings = await this.OrgSportAthleteReportHeadings();
				this.CurrentOrganization.athleteReportHeadings = cloneDeep(headings);
			}
		}
		if( this.IsEmpty(this.CurrentOrganization.athleteReportHeadings) ) {
			this.CurrentOrganization.athleteReportHeadings = [
				{name:'Physical', categories:[], expanded:false},
				{name:'Mental', categories:[], expanded:false},
			];
		}
		this.editOrganization = cloneDeep(this.CurrentOrganization);
		this.editAthleteReportsReady = true;
	}

	athleteReportKey: number = 0;
	get AthleteReportHeadings(): Array<AthleteReportCategoryHeading> {
		return this.editOrganization.athleteReportHeadings;
	}
	onAddAthleteReportHeadingComplete(heading: AthleteReportCategoryHeading) {
        this.setFocus(this.$refs.athletereportHeading);
        this.editOrganization.athleteReportHeadings.push(heading);
		this.athleteReportKey++;
	}
	onRemoveAthleteReportHeading(index: number) {
		this.editOrganization.athleteReportHeadings.splice(index, 1);
		this.athleteReportKey++;
	}
    onRemoveAllAthleteReportHeadings() {
        this.editOrganization.athleteReportHeadings = [];
        this.setFocus(this.$refs.athletereportHeading);
    }
	async onDefaultAthleteReportHeadings() {
		const headings: Array<AthleteReportCategoryHeading> = await this.OrgSportAthleteReportHeadings();
		this.editOrganization.athleteReportHeadings.splice(0, this.editOrganization.athleteReportHeadings.length);
		for(const h of headings) { this.editOrganization.athleteReportHeadings.push(cloneDeep(h)) }
		this.athleteReportKey++;
	}
	onResetAthleteReportHeadings() {
		this.editOrganization.athleteReportHeadings.splice(0, this.editOrganization.athleteReportHeadings.length);
		for( const h of this.CurrentOrganization.athleteReportHeadings ) {
			this.editOrganization.athleteReportHeadings.push( JSON.parse(JSON.stringify(h)) );
		}
		this.athleteReportKey++;
	}

	onEditLandingPage() {
		
	}

	savingOrganization: boolean = false;
	async onSaveOrganization() {
		this.savingOrganization = true;
		await orgAdminStore.patchOrganization(this.editOrganization);
		this.savingOrganization = false;
	}
}
