
import { Component, Mixins } from 'vue-property-decorator';
import { orgAdminStore } from '@/store';
import { BAIconsMixin, VuetifyMixin, OrgAdminRoutingMixin } from '@/mixins';
import Page from '@/views/Page.vue';
import { OrganizationModel } from '@/models/organization/OrganizationModel';

@Component({
	components: {
		Page
	}
})
export default class OrgsAdminDashboard extends Mixins(VuetifyMixin, BAIconsMixin, OrgAdminRoutingMixin){
	created(): void{
		orgAdminStore.loadOrganizations();
	}

	search = '';

	headers = [
		{ text: 'Organization', value: 'name', sortable: true, align: 'start' },
		{ text: 'Location', value: 'Location' },
		{ text: 'Type', value: 'type' },
		{ text: 'Teams', value: 'TeamCount', align: 'center' },
		{ text: '', value: 'actions' }
	]

	get Loading(): boolean{
		return !orgAdminStore.organizationsInitialized || orgAdminStore.organizationsLoading;
	}
	get OrgsReady(): boolean{
		return orgAdminStore.organizationsInitialized;
	}
	get Organizations(): OrganizationModel[]{
		return orgAdminStore.organizations;
	}
}
