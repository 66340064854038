
import { Component, Mixins } from 'vue-property-decorator';
import { orgAdminStore } from '@/store';
import Page from '@/views/Page.vue';
import OrgCard from '@/components/orgAdmin/OrgCard.vue';
import OrgTeamCard from '@/components/orgAdmin/OrgTeamCard.vue';
import { TeamModel } from '@/models/team';
import { VuetifyMixin, OrgResolverMixin, CoachRoutingMixin, AppHostnameMixin, DebounceMixin, SportsSelectorMixin, StringsMixin, BAIconsMixin, OrgAdminRoutingMixin } from '@/mixins';
import { SportName, Gender, TeamGenderValues } from '@/../types/enums';
import { AgeGroup, AgeGroupValues } from '@best-athletes/ba-types';
import * as OrgAdminRoutes from '@/../types/constants/org-admin.routes';
import * as AdminRoutes from '@/../types/constants/admin.routes';
import { ShareTeam, EditTeam } from '@/../types/constants/web_client_user.routes';
import { teamApi } from '@/api/TeamApi';

@Component({
	components: {
		OrgTeamCard,
		Page,
		OrgCard,
	}
})
export default class OrgAdminManageTeams extends Mixins(AppHostnameMixin, SportsSelectorMixin, DebounceMixin, CoachRoutingMixin, OrgResolverMixin, VuetifyMixin, StringsMixin, BAIconsMixin, OrgAdminRoutingMixin){

	async mounted() {
		await this.loadSports();
		await this.loadOrgTeams(this.organizationId);
	}

	get TableLoading(): boolean{
		return !orgAdminStore.loadOrganizationTeamsInitialized || orgAdminStore.loadOrganizationTeamsLoading;
	}

	get TotalActive(): number {
		return this.ActiveTeams.length;
	}
	get TotalArchived(): number {
		return this.ArchiveTeams.length;
	}
	get TotalItems(): number {
		return orgAdminStore.totalTeams;
	}

	searchLoading: boolean = false;
	debounceSearch(): void{
		this.searchLoading = true;
		this.debounceCallback('teamSearch', () => {
			this.searchRegExp = new RegExp(this.teamSearch, 'ig');
			this.searchLoading = false;
		}, 300);
	}

	teamSearch: string = "";
	sportsFilter: SportName[] = [];
	genderFilter: Gender[] = [];
	ageFilter: AgeGroup[] = [];
	searchRegExp: RegExp | null = null;
	get TeamList(): TeamModel[]{
		const filteredTeams = orgAdminStore.teamList
			.filter(t => this.sportsFilter.length === 0 ? t : this.sportsFilter.includes(t.sportId as SportName))
			.filter(t => this.genderFilter.length === 0 ? t : this.genderFilter.includes(t.gender as Gender))
		if(this.searchRegExp === null) return filteredTeams;
		return filteredTeams.filter(t => this.searchRegExp.test(t.name));
	}
	get ActiveTeams(): TeamModel[] {
		return this.TeamList.filter( t => !t.archive );
	}
	get ArchiveTeams(): TeamModel[] {
		return this.TeamList.filter( t => t.archive );
	}
	get TeamGenderValues(): Gender[]{
		return TeamGenderValues;
	}
	get AgeGroupValues(): AgeGroup[]{
		return AgeGroupValues;
	}

	onEditOrganization() {
		this.$router.push({
			name: AdminRoutes.OrgEdit,
			params:{
				editOrganizationId: this.organizationId,
			}
		});
	}

	onClickTeam(item) {
		this.gotoOrgTeam(item.id)
	}
	onEditTeam(teamId: string) {
		this.$router.push({
			name: EditTeam,
			params: {
				teamId: teamId,
			}
		});
	}
	async onShareRoster(team: TeamModel) {
		try{
			await teamApi.shareTeam(team.id);
		} catch(e) {
			console.log(e);
		}
		this.gotoShareRoster(team);
	}
	gotoShareRoster(team: TeamModel) {
		this.$router.push({
			name: ShareTeam,
			params:{
				teamId: team.id,
				owner: 'coach',
				sharingUrlId: 'share'
			}
		});
	}
	async onClickTeamProgress(team: TeamModel) {
		await this.gotoTeamProgressDashboard(team.id);
	}
	async onClickArchive(teamId: string) {
		const team = await teamApi.findById(teamId);
		if( !team ) return;
		team.archive = !team.archive;
		await teamApi.save(team);
		this.loadOrgTeams(this.organizationId);
	}
	showArchive = false;
	get hasTeamArchive(): boolean {
		return( this.ArchiveTeams.length > 0 );
	}

	// Data Table elements
	get TeamData(): Array<any> {
		return this.showArchive? this.TeamList : this.ActiveTeams;
	}
	TableHeaders = [
		{ text: 'Team', align: 'start', value: 'name', sortable: false  },
		{ text: 'Players', value: 'players', sortable: false },
		{ text: 'Gender', value: 'gender', sortable: false },
		{ text: 'Location', value: 'location', sortable: false },
		{ text: 'Head Coach', value: 'coach', sortable: false },
		{ text: '', value: 'actions', sortable: false },
	]
}
